import React from 'react';

const  clubsCSR = "1-800-724-6527";

export const clubsMsg = {
    emailFormatError: "Please enter a properly formatted email address.",
    emailRequiredError: "This field is required.",
    emailApiError: "We don't recognize this email address, please try again.",
    emailInactiveChatOn: `The account associated with this email address may not be active. For help, <span style="color:#0098C8;">chat</span> or call ${clubsCSR}`,
    emailInactiveChatOff: `The account associated with this email address may not be active. For help, call ${clubsCSR}`,
    passwordApiError: "This password is incorrect. Please try again or click \"Forgot password\" below to reset.",
    passwordPolicyError: "The password doesn't meet our security requirements. Please reset your password by clicking on 'Forgot Password'",
    passwordLockedChatOn: `Your account has been locked for security. Please try again in 30 minutes. For help, use chat on the homepage or call ${clubsCSR}.`,
    passwordLockedChatOff: `Your account has been locked for security. Please try again in 30 minutes. For help, call ${clubsCSR}.`,
    passwordLocked: `Your account has been locked for security. You will not be able to change your password. Please try again in 30 minutes, or else please contact Customer Service at 1-800-724-6527`,
    forgotPasswordEmailFormatError: "Please enter a properly formatted email address.",
    forgotPasswordEmailApiError: "We don�t recognize this email address, please try again.",
    forgotPasswordEmailInactiveChatOn: `The account associated with this email address may not be active. Please try another email. For help, <span style="color:#0098C8;">chat</span> or call ${clubsCSR}.`,
    forgotPasswordEmailInactiveChatOff: `The account associated with this email address may not be active. Please try another email. For help, call ${clubsCSR}.`,
    forgotPasswordEmailInactive: `The account associated with this email address may not be active. Please try another email. For help, call ${clubsCSR}.`,
    forgotPasswordEmailLockedChatOn: `Your account has been locked for security. You will not be able to change your password. Please try again in 30 minutes. For help, <span style="color:#0098C8;">chat</span> or call ${clubsCSR}.`,
    forgotPasswordEmailLockedChatOff: `Your account has been locked for security. You will not be able to change your password. Please try again in 30 minutes. For help, call ${clubsCSR}.`,
    forgotPasswordEmailLocked: `Your account has been locked for security. You will not be able to change your password. Please try again in 30 minutes. For help, call ${clubsCSR}.`,
    forgotYourEmailNameRequiredError: "This field is required.",
    forgotYourEmailNameMinError: "Your name must include at least two letters.",
    forgotYourEmailZipCodeRequiredError: "This field is required.",
    forgotYourEmailZipCodeMinError: "Please enter a valid five-digit U.S ZIP Code",
    resetPasswordConfirmMatchError: "Your password did not match. Try again.",
    resetPasswordTokenError: "The Link has expired, please click on Resend Link to have another email sent to you.",
    unavailableError: `Something went wrong, please try again later or contact ${clubsCSR}.`
}

export default clubsMsg;